import LinearGradient from '@/components/basic/linear-gradient';
type Route = any;
import {Animated, View, StyleSheet} from 'react-native';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import React from 'react';
import Text from '@/components/basic/text';
import theme from '@/style';
import {useTranslation} from 'react-i18next';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';
export interface TabHeaderProps {
  index: number;
  routes: Route[];
  itemEqual?: boolean;
  onTabPress?: (i: number) => void;
  orderKey?: string;
  orderNum?: number;
}

const TabHeader = (props: TabHeaderProps) => {
  const {
    routes = [],
    itemEqual = false,
    onTabPress,
    index = 0,
    orderKey = 'order',
    orderNum = 0,
  } = props;
  const slideAnim = React.useRef(new Animated.Value(0)).current;
  const [position, setPosition] = React.useState<number[]>([]);
  const {i18n} = useTranslation();
  const onMeasure = (l: number, i: number) => {
    position[i] = l;
    setPosition([...position]);
  };

  React.useEffect(() => {
    if (position.length > 0) {
      Animated.timing(slideAnim, {
        toValue: position[index],
        useNativeDriver: true,
        duration: 200,
      }).start();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, position]);

  const {calcActualSize} = useScreenSize();

  const styles = StyleSheet.create({
    container: {
      height: calcActualSize(48),
    },
    sliding: {
      position: 'absolute',
      bottom: calcActualSize(11),
    },
  });

  return (
    <LinearGradient
      colors={['#fff', '#F6F7FA']}
      style={[theme.padding.lrxxl, styles.container]}>
      <View style={[theme.flex.row, theme.flex.between, theme.fill.fill]}>
        {routes.map((item, _i) => {
          return (
            <NativeTouchableOpacity
              onLayout={e => {
                const left = e.nativeEvent.layout.x;
                const selfWidth = e.nativeEvent.layout.width;
                const target = left + (selfWidth - 20) / 2;
                onMeasure(Number(target.toFixed(1)), _i);
              }}
              onPress={() => onTabPress && onTabPress(_i)}
              key={_i}
              style={[
                theme.padding.topl,
                theme.flex.centerByCol,
                itemEqual && {
                  ...theme.flex.flex1,
                },
              ]}>
              <View>
                <Text
                  calc
                  textAlign="center"
                  color={
                    _i === index
                      ? theme.basicColor.dark
                      : theme.fontColor.second
                  }
                  fontFamily={_i === index ? 'fontInterBold' : 'fontInter'}
                  size="medium">
                  {i18n.t(`game-page.tab.${item.title}`)}
                </Text>
                {item.key === orderKey && orderNum > 0 && (
                  <Badge num={orderNum} />
                )}
              </View>
            </NativeTouchableOpacity>
          );
        })}
        {position.length === routes.length && position[0] && (
          <Animated.View
            style={[
              styles.sliding,
              theme.flex.center,
              {
                transform: [
                  {
                    translateX: slideAnim,
                  },
                ],
              },
            ]}>
            <View
              style={[
                {
                  width: calcActualSize(theme.iconSize.m),
                  height: calcActualSize(theme.paddingSize.xxs),
                },
                theme.background.primary,
              ]}
            />
          </Animated.View>
        )}
      </View>
    </LinearGradient>
  );
};

const Badge = ({num = 0}: {num?: number; itemEqual?: boolean}) => {
  const {calcActualSize} = useScreenSize();
  const styles = StyleSheet.create({
    badgeContainer: {
      position: 'absolute',
      right: -calcActualSize(8),
      top: -calcActualSize(8),
      height: calcActualSize(20),
      borderRadius: calcActualSize(20),
      minWidth: calcActualSize(20),
    },
  });
  return (
    <View
      style={[
        styles.badgeContainer,
        theme.background.primary,
        theme.flex.center,
      ]}>
      <Text
        fontSize={10}
        color={theme.basicColor.white}
        fontFamily="fontInterBold">
        {num}
      </Text>
    </View>
  );
};

export default React.memo(TabHeader);
